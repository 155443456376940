import handleResponse from './handleResponse';
import config from '../config/config';

export const getEndpoint = () => config.apiUrl + config.apiPrefix;

const endPoint = getEndpoint();

const apiService = async (url, options) => {
    //const opts = createRequestOptions(Object.assign({ url }, options))
    let response = await fetch(endPoint + url, options);
    return handleResponse(response);
}

export default apiService;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const PageNotFound = () => (
    <Grid 
        container
        direction={'column'}
        alignItems={'center'}
        justify={'center'}
        style={{height: '100vh' }}
    >
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Page Not found
                </Typography>
                <Typography component="p">
                    Looks like you've followed a broken link or entered a 
                    URL that doesn't exist on this site.
                </Typography>
            </CardContent>
        </Card>
    </Grid>
)

export default PageNotFound;

const env = (window.__data && window.__data.stage) || 'prod';

const configs = {
    dev: {
        apiUrl: 'http://localhost:4000',
        apiPrefix: '',
        calculatorBaseName: '/calculator',
    },
    test: {
        apiUrl: 'https://test-api.ps-bizzabo.com',
        apiPrefix: '/api',
        calculatorBaseName: '/',
    },
    prod: {
        apiUrl: 'https://api.ps-bizzabo.com',
        apiPrefix: '/api',
        calculatorBaseName: '/',
    },
};

export default { ...configs[env] };
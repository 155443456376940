import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const ErrorNotification = () => (
    <Grid
        container
        direction={'column'}
        alignItems={'center'}
        justify={'center'}
        style={{ height: '100vh' }}
    >
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Error Loading Page
                </Typography>
                <Typography component="p">
                    Something went wrong. Try reloading the page.
                </Typography>
            </CardContent>
        </Card>
    </Grid>
);

export default ErrorNotification;

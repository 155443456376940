import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import EventsCalendar from './components/EventsCalendar';
import PageNotFound from './components/PageNotFound';
import './App.css';

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route
                        path="/:configId/events"
                        component={EventsCalendar}
                    />
                    <Route component={PageNotFound} />
                </Switch>
            </Router>
        );
    }
}

export default App;

import { useState, useEffect, useReducer } from 'react';
import apiService from '../apiService';
import getCookieValue from '../utils/getCookie';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';
const FETCH_RESET = 'FETCH_RESET';
const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case FETCH_RESET:
            return {
                isLoading: false,
                isError: false,
            };
        default:
            throw new Error();
    }
};

export const useRestDataApi = () => {
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
    });
    const [req, setReq] = useState();

    useEffect(() => {
        let didCancel = false;
        if (!req) return;

        const fetchData = async () => {
            dispatch({ type: FETCH_INIT });
            const { url, ...rest } = req;
            try {
                const { body } = await apiService(url, rest);
                if (!didCancel) {
                    dispatch({
                        type: FETCH_SUCCESS,
                        payload: body,
                    });
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: FETCH_FAILURE });
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [req]);

    const doRequest = req => setReq(req);
    const doReset = () => dispatch({ type: FETCH_RESET });

    return { ...state, doRequest, doReset };
};

export const useFetchDataApi =(url, options) => {
    const { doRequest, ...rest } = useRestDataApi();
    useEffect(() => {
        if (url) {
            doRequest({
                url,
                method: 'GET',
                ...options,
            });
        }
    }, [url]);
    return {
        ...rest,
    };
};

// auth token for calculator API
const calculatorAuthToken =
    getCookieValue('bz-calc-auth') || localStorage.getItem('bz-calc-auth');

export const useGetQuote = (quoteId) => {
    const result = useFetchDataApi(quoteId && `/calculator-quote/${quoteId}`, {
        headers: {
            Authorization: `Bearer ${calculatorAuthToken}`,
        },
    });

    return result;

}
export const usePostQuote = () => {
    const { doRequest, ...rest } = useRestDataApi();
    return {
        ...rest,
        doRequest: data =>
            doRequest({
                body: JSON.stringify(data),
                url: '/calculator-quote',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${calculatorAuthToken}`,
                },
            }),
    };
};

export default useRestDataApi;

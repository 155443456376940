import React from 'react';
import get from 'lodash/get';
import ErrorNotification from '../ErrorNotification';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useFetchDataApi } from '../../hooks/useDataApi';

const EventListRowsTemplate = React.lazy(() => import('../EventListRowsTemplate'));
const EventListCardsTemplate = React.lazy(() => import('../EventListCardsTemplate'));


const templates = {
    rows: EventListRowsTemplate,
    cards: EventListCardsTemplate,
};

const SuspenseFallback = () => (
    <Grid container justify={'center'} alignItems={'center'} style={{ height: 300 }}>
        <CircularProgress />
    </Grid>
)

const EventsCalendar = ({ match }) => {
    const { data, isLoading, isError } = useFetchDataApi(
        `/config/${match.params.configId}`
    );

    if (isError) {
        return <ErrorNotification />;
    }

    if (isLoading || !data) {
        return null;
    }

    const template = get(data, 'config.template', 'rows');
    const Component = templates[template];

    return (
        <React.Suspense fallback={<SuspenseFallback />}>
            <Component events={data.events} config={data.config} />
        </React.Suspense>
    );
};

export default EventsCalendar;
